<template>
  <div>
    <div
      v-if="!isLoadingCarts"
      class="w-100"
    >
      <div v-if="isNoCartsFound">
        <explore-collection />
      </div>
      <div
        v-else-if="isMultipleCart"
        class="w-100"
      >
        <!-- Multiple Carts -->
        <carts :load-carts="loadCarts" />
      </div>
      <div
        v-else
        class="w-100"
      >
        <!-- Single Cart component from previous code -->
        <cart-total />
      </div>
    </div>
    <b-card
      v-else
      class="text-center p-4 mt-2"
    >
      <b-spinner />
    </b-card>
    <cart-modals
      import-cart-modal-id="import_from_excel_cart"
      :on-cart-import-success="loadCarts"
    />
  </div>
</template>

<script>
import { BCard, BSpinner } from 'bootstrap-vue'
import { LOAD_CARTS, RESET_CART_ERRORS, RESET_EXPANDED_CARTS } from '@/store/modules/checkout-v2.module'
import { mapState } from 'vuex'
import Carts from './Carts.vue'
import CartTotal from '../checkout/CartTotal.vue'
import ExploreCollection from './ExploreCollection.vue'
import CartModals from '../checkout/CartModals.vue'

export default {
  name: 'MultipleCart',
  components: {
    BCard,
    BSpinner,
    Carts,
    CartTotal,
    ExploreCollection,
    CartModals,
  },
  computed: {
    ...mapState({
      isMultipleCart: state => state.checkout.isMultipleCart,
      isLoadingCarts: state => state.checkout.isLoadingCarts,
      isNoCartsFound: state => state.checkout.isNoCartsFound,
    }),
  },
  created() {
    // Reset the expanded carts
    this.$store.commit(RESET_EXPANDED_CARTS)
    // Reset the cart errors
    this.$store.commit(RESET_CART_ERRORS)
    // Load the carts
    this.loadCarts()
  },
  methods: {
    async loadCarts() {
      await this.$store.dispatch(LOAD_CARTS)
    },
  },
}
</script>
